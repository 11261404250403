import { colors } from '@mui/material';

export const courseCategories = [
  {
    color: colors.blue,
    icon: 0,
    title: 'Release Notes',
    subtitle:
      'visit the release notes to find out whats has been updtaed in the current version.',
    link: 'https://docs.vrgeoscience.com/release-notes',
  },
  {
    color: colors.purple,
    icon: 1,
    title: 'Main Webpage',
    subtitle:
      'Go to the main VRGS web site to find out the latest news from VRGeoscience.',
    link: '/',
  },
  {
    color: colors.amber,
    icon: 2,
    title: 'Documentation',
    subtitle: 'Visit the ever growing documentation site.',
    link: 'https://www.vrgeoscience.com/docs',
  },
  {
    color: colors.indigo,
    icon: 3,
    title: 'Demo Datasets',
    subtitle: 'Choose a demo dataset to try out VRGS.',
    link: '/tour3d-trefor-rocks',
  },
  {
    color: colors.pink,
    icon: 4,
    title: 'Get Support',
    subtitle: 'Contact support to get help for VRGS.',
    link: '/contact-page',
  },
  {
    color: colors.green,
    icon: 5,
    title: 'YouTube Channel',
    subtitle: 'Head to the YouTube channel to find videp tutorials.',
    link: 'https://www.youtube.com/channel/UCBjQm2gPzdbHMw_uxofchtw',
  },
  {
    color: colors.deepOrange,
    icon: 6,
    title: 'Follow us on Twitter',
    subtitle: 'Choose thousands of finaces & sales online course.',
    link: 'https://twitter.com/vrgeoscience',
  },
  {
    color: colors.red,
    icon: 7,
    title: 'Follow us on LinkedIn',
    subtitle: 'Choose thousands of graphic design online course.',
    link: 'https://www.linkedin.com/company/vrgeoscience/',
  },
];

export const videos = [
  {
    source: 'xz9a_k2R1vE',
    title: 'VRGS Quick Tips : Contours and grid lines',
    subtitle:
      'This week’s VRGS quick tip continues the theme of showing scale in digital outcrop models, this time by adding contours and grid lines.',
  },
  {
    source: 'zGny74sjOT4',
    title: 'VRGS Quick Tips : Adding and formatting scale bars.',
    subtitle:
      'Including a scale is essential for maps, photographs and field sketches in the geosciences, and the same goes for your digital outcrop models.',
  },
  {
    source: 'iXNtQsn37uU',
    title:
      'VRGS Quick Tip : Enabling level-of-detail (LOD) display on triangulated mesh data.',
    subtitle:
      'When visualising large multi-mesh digital outcrop models, meshes that are further away can be rendered with fewer triangles to speed up framerates. This feature can be enabled in VRGS on any imported mesh, and here is how to do it:',
  },
  {
    source: 'CKj2FqGH3RU',
    title:
      'VRGS Quick Tips : Adding billboards to digital outcrop models with the drag and drop approach.',
    subtitle:
      'This VRGS Quick Tip video covers how to add billboards to your model with a simple drag and drop approach.  In VRGS billboards are images which are used to add extra information to your model, such as photographs, maps or information panels. ',
  },

  {
    source: 'E1IRnSMS-iY',
    title: 'Geological fracture mapping in VRGS 3.0',
    subtitle: 'Geological fracture mapping in VRGS 3.0.',
  },
  {
    source: 'O4TN8-3h6E0',
    title: 'Tutorial: Point cloud and mesh segmentation in VRGS',
    subtitle:
      'Learn how to segment point cloud and mesh data in VRGS 3.0 using attributes and the stereonet view.',
  },
  {
    source: '1RqI4UGTjHY',
    title: 'Quick Tips : Orthopanels',
    subtitle:
      'An orthopanel is an orthographic projection of the outcrop data, removing the effects of perspective and dip to give you a clearer view of the geology. Orthopanels in VRGS are interactive, and can be interpreted on to allow you to extend interpretations away from the outcrop.',
  },
  {
    source: 'rgzzg2U-2Uk',
    title: 'Quick Tips : Using Attributes',
    subtitle:
      'How to create attributes from your digital outcrop models. These attributes make interpretation and classification easier by enhancing information in the model. VRGS provides an extensive suite of attributes to choose from. You can use these for both manual and automated interpretation techniques.',
  },
  {
    source: 'Th7uFxCSmIc',
    title: 'Quick Tips : Flight paths for video recording',
    subtitle:
      'In VRGS a flight path is a route defined through a model via a series of key-frames. These flight paths can be used for creating videos from your outcrop models.  This quick tip shows how to set up a basic flight path and record the results to video via the in-built recorder.',
  },
];
