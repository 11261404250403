import React from 'react';
import { useState, useEffect } from 'react';
import Section from 'components/organisms/Section/Section';
import { useLocation } from '@reach/router';
import SectionAlternate from 'components/organisms/SectionAlternate/SectionAlternate';
import { Categories, Hero, VideoSection } from './components';
import { courseCategories, videos } from './data';
import SectionHeader from 'components/molecules/SectionHeader';

const Upgrade = () => {
  const location = useLocation();
  const [theVersion, setEventData] = useState({
    major: '0',
    minor: '0',
    build: '0',
  });

  const getData = () => {
    fetch('https://apps.vrgeoscience.com/vrgs/CurrentVersion.json')
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setEventData(myJson);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  const vals = location.search.split('_');
  vals[0] = vals[0].replace('?', '');
  const vidNum = Math.floor(Math.random() * 8);
  return (
    <div>
      <Section>
        <SectionHeader
          title={
            <span>Welcome to VRGS : Virtual Reality Geological Studio</span>
          }
          subtitle=""
          fadeUp
          titleVariant="h3"
        />
        <Hero
          vrgsUpgradeFrom={
            vals.length == 3
              ? {
                  major: vals[0] | 0,
                  minor: vals[1] | 0,
                  build: vals[2] | 0,
                }
              : {
                  major: vals[0] | 0,
                  build: vals[1] | 0,
                }
          }
          vrgsVersion={theVersion}
          videoSrc={'CaAevvXra6A'}
          videoTitle={'Onboarding video for VRGS 3.0'}
          videoSubtitle={
            'Getting started with VRGS. Creating a new project, model navigation, and some basic interpretation. Enough to get you up and running in a few minutes.'
          }
        />
      </Section>
      <SectionAlternate>
        <SectionHeader
          title="VRGS Training and Tutorials"
          subtitle="Find tutorials and quick tips on YouTube."
          fadeUp
          titleVariant="h2"
        />
        <VideoSection
          videoSrc={videos[vidNum].source}
          videoTitle={videos[vidNum].title}
          videoSubtitle={videos[vidNum].subtitle}
        />
      </SectionAlternate>
      <Section>
        <Categories data={courseCategories} />
      </Section>
    </div>
  );
};

export default Upgrade;
