import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Grid from '@mui/material/Grid/Grid';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import LearnMoreLink from 'components/atoms/LearnMoreLink/LearnMoreLink';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

const VideoSection = (props) => {
  const {
    data,
    className,
    videoSrc,
    videoTitle,
    videoSubtitle,
    buttonName,
    buttonLink,
    ...rest
  } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
      <Grid item xs={12} md={6} data-aos={'fade-up'}>
        <div
          data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <LiteYouTubeEmbed
            id={videoSrc}
            title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6} data-aos={'fade-up'}>
        <SectionHeader
          title={videoTitle}
          titleVariant="h2"
          subtitleVariant="body1"
          subtitle={videoSubtitle}
          align={isMd ? 'left' : 'center'}
          ctaGroup={[
            <LearnMoreLink
              title="See the tutorials on YouTube"
              href="https://www.youtube.com/watch?v=zGny74sjOT4&list=PLPWTN5nOcsd8xQpOZ6M0nLTu93a8A6mZw"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

VideoSection.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default VideoSection;
