import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Grid from '@mui/material/Grid/Grid';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import LearnMoreLink from 'components/atoms/LearnMoreLink/LearnMoreLink';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import Typography from '@mui/material/Typography/Typography';
const useStyles = makeStyles((theme) => ({
  videoIframe: {
    boxShadow: `0 5px 15px 0 ${theme.palette.cardShadow}`,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
    },
  },
}));

const checkForUpgrade = (vrgsVersion, vrgsUpgradeFrom) => {
  const verFrom =
    vrgsUpgradeFrom.major * 100000 +
    vrgsUpgradeFrom.minor * 1000 +
    vrgsUpgradeFrom.build;
  const verTo =
    vrgsVersion.major * 100000 + vrgsVersion.minor * 1000 + vrgsVersion.build;
  return verFrom > verTo;
};

const Hero = (props) => {
  const {
    data,
    className,
    videoSrc,
    videoTitle,
    videoSubtitle,
    videoThumbnail,
    buttonName,
    buttonLink,
    vrgsVersion,
    vrgsUpgradeFrom,
    ...rest
  } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  var Upgrade = false;
  if (vrgsVersion.release) Upgrade = true;
  else Upgrade = checkForUpgrade(vrgsUpgradeFrom, vrgsVersion);

  return (
    <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
      <Grid item xs={12} md={6} data-aos={'fade-up'}>
        <SectionHeader
          title={
            <Typography
              component="span"
              variant="inherit"
              color={Upgrade ? 'secondary' : 'primary'}
            >
              {Upgrade ? 'Its time to update to ' : 'You are up to date with '}
              VRGS {vrgsVersion.major}.{vrgsVersion.minor} build{' '}
              {vrgsVersion.build}
            </Typography>
          }
          titleVariant="h2"
          subtitleVariant="body1"
          subtitle={
            Upgrade ? (
              <span>
                {vrgsUpgradeFrom.minor
                  ? `You are currently running version ${vrgsUpgradeFrom.major}.${vrgsUpgradeFrom.minor} build ${vrgsUpgradeFrom.build} `
                  : `You are currently running version ${vrgsUpgradeFrom.major} build ${vrgsUpgradeFrom.build} `}
                <br /> VRGS will update when you next restart. <br /> If it does
                not then consult your licensing email for the download link to
                the installer.
              </span>
            ) : (
              <span>
                First time using VRGS?
                <br /> Watch the video here to learn how to start a new project,
                import and navigate around a model, and do some basic
                interpretation.
                <br /> Enough to get you up and running in a few minutes.
              </span>
            )
          }
          align={isMd ? 'left' : 'center'}
          ctaGroup={[
            <LearnMoreLink
              title="Read the release Notes"
              href="https://docs.vrgeoscience.com/docs/release-notes/r3-0-notes"
            />,
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6} data-aos={'fade-up'}>
        <div
          data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <LiteYouTubeEmbed id={videoSrc} title={videoSubtitle} />
        </div>
      </Grid>
    </Grid>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;

// import React from 'react';
// import PropTypes from 'prop-types';
// import makeStyles from '@mui/styles/makeStyles';
// import Button from '@mui/material/Button/Button';
// import Typography from '@mui/material/Typography/Typography';
// import LiteYouTubeEmbed from 'react-lite-youtube-embed';
// import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
// import HeroShaped from 'components/organisms/HeroShaped/HeroShaped';

// const useStyles = makeStyles(() => ({
//   image: {
//     objectFit: 'cover',
//   },
//   fontWeight700: {
//     fontWeight: 700,
//   },
// }));

// const checkForUpgrade = (vrgsVersion, vrgsUpgradeFrom) => {
//   const verFrom =
//     vrgsUpgradeFrom.major * 100000 +
//     vrgsUpgradeFrom.minor * 1000 +
//     vrgsUpgradeFrom.build;
//   const verTo =
//     vrgsVersion.major * 100000 + vrgsVersion.minor * 1000 + vrgsVersion.build;
//   return verFrom > verTo;
// };

// const Hero = (props) => {
//   const { vrgsVersion, vrgsUpgradeFrom, className, ...rest } = props;
//   const classes = useStyles();
//   var Upgrade = false;
//   if (vrgsVersion.release) Upgrade = true;
//   else Upgrade = checkForUpgrade(vrgsUpgradeFrom, vrgsVersion);

//   return (
//     <div className={className} {...rest}>
//       <HeroShaped
//         leftSide={
//           <div>
//             <SectionHeader
//               title={
//                 <span>
//                   <Typography
//                     component="span"
//                     variant="inherit"
//                     color={Upgrade ? 'secondary' : 'primary'}
//                   >
//                     {Upgrade
//                       ? 'Its time to update to '
//                       : 'You are up to date with '}
//                     VRGS {vrgsVersion.major}.{vrgsVersion.minor} build{' '}
//                     {vrgsVersion.build}
//                   </Typography>
//                 </span>
//               }
//               subtitle={
//                 Upgrade
//                   ? vrgsUpgradeFrom.minor
//                     ? `You are currently running version ${vrgsUpgradeFrom.major}.${vrgsUpgradeFrom.minor} build ${vrgsUpgradeFrom.build} `
//                     : `You are currently running version ${vrgsUpgradeFrom.major} build ${vrgsUpgradeFrom.build} `
//                   : ''
//               }
//               ctaGroup={[
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   size="large"
//                   href="https://docs.vrgeoscience.com/release-notes"
//                 >
//                   View Release Notes
//                 </Button>,
//               ]}
//               align="left"
//               titleVariant="h3"
//             />
//           </div>
//         }
//         rightSide={
//           <LiteYouTubeEmbed
//             id="CaAevvXra6A"
//             title="Getting started with VRGS. Creating a new project, model navigation, and some basic interpretation. Enough to get you up and running in a few minutes."
//           />
//         }
//       />
//     </div>
//   );
// };

// Hero.propTypes = {
//   /**
//    * External classes
//    */
//   className: PropTypes.string,
// };

// export default Hero;
