import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import CardCategoryLink from 'components/organisms/CardCategoryLink/CardCategoryLink';

import DesktopWindowsIcon from '@mui/icons-material/DesktopWindowsTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import LandscapeTwoTone from '@mui/icons-material/LandscapeTwoTone';

const Categories = (props) => {
  const { data, className, ...rest } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={
          <span>
            Where do you want to go{' '}
            <Typography color="secondary" variant="inherit" component="span">
              next?
            </Typography>
          </span>
        }
        subtitle="Continue to other parts of the website."
        fadeUp
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={3}
            data-aos="fade-up"
          >
            <CardCategoryLink
              variant="outlined"
              align={isMd ? 'left' : 'center'}
              liftUp
              title={item.title}
              subtitle={item.subtitle}
              href={item.link}
              fontIconClass={item.icon}
              color={item.color}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Categories.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Categories;
